import React from "react";
import { string, bool, shape } from "prop-types";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import Heading from "../../../Base/HeadingBuilder";
import CtaPrimary from "../../../Base/CtaPrimary";
import { viewports } from "../../../../style-vars";
import useStartMotion from "../../../../hooks/use-start-motion";

const fadeVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
};

const SimpleHero = ({
  className,
  title,
  customHeadingSize,
  subTitle,
  subtext,
  ctaPrimary,
  withBlueDotBackground,
  titleCustomClass,
  animations,
  darkMode,
  paddingBottom,
}) => {
  const { ref, controls } = useStartMotion();

  return (
    <motion.section
      data-cy="simple-hero"
      className={`
      relative
      max-w-1440 w-full
      flex flex-col items-center text-center
      ${withBlueDotBackground ? "pb-12 lg:pb-20" : ""}
      ${paddingBottom}
      ${className}
    `}
      ref={ref}
      initial={isMobileOnly || !animations ? "visible" : "hidden"}
      variants={fadeVariants}
      animate={controls}
      transition={{ duration: 0.8 }}
    >
      {withBlueDotBackground && (
        <div
          className={`
          blue-dot-bg
          absolute bg-primary rounded-full
          z-0
        `}
        />
      )}
      {subTitle && (
        <Heading
          level={2}
          injectionType={3}
          className="mb-4 lg:mb-6 text-center font-normal"
        >
          {subTitle}
        </Heading>
      )}
      <Heading
        level={1}
        injectionType={1}
        withBlueSymbol
        className={`
          ${
            customHeadingSize ||
            "px-3.5 lg:px-0 md:max-w-80-percent xl:max-w-80-percent z-1"
          }
          ${titleCustomClass}
        `}
      >
        {title}
      </Heading>
      {subtext && (
        <p
          className={`
          mt-0 lg:mt-2 mb-6 lg:mb-10
          px-4 lg:px-0
          md:max-w-60-percent xl:max-w-50-percent
          text-black-300 text-p-large z-1
        `}
        >
          {subtext}
        </p>
      )}
      {ctaPrimary && (
        <CtaPrimary
          className="z-1"
          title={ctaPrimary.title}
          target={ctaPrimary.target}
        />
      )}

      <style jsx>{`
        .blue-dot-bg {
          top: -5%;
          min-width: 200px;
          min-height: 200px;
          opacity: 0.2;
          filter: blur(200px);
        }

        @media ${viewports.lgOrBigger} {
          .blue-dot-bg {
            top: -25%;
            min-width: 450px;
            min-height: 450px;
          }
        }
      `}</style>
    </motion.section>
  );
};

SimpleHero.propTypes = {
  className: string,
  customHeadingSize: string,
  title: string.isRequired,
  subTitle: string,
  subtext: string,
  ctaPrimary: shape({
    title: string,
    target: string,
  }),
  withBlueDotBackground: bool,
  titleCustomClass: string,
  animations: bool,
  darkMode: bool,
  paddingBottom: string,
};

SimpleHero.defaultProps = {
  className: "",
  customHeadingSize: "",
  subTitle: null,
  subtext: "",
  ctaPrimary: null,
  withBlueDotBackground: false,
  titleCustomClass: "",
  animations: true,
  darkMode: false,
  paddingBottom: "pb-12 lg:pb-20",
};

export default SimpleHero;
